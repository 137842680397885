import axios from 'axios';
import { getApiOrigin } from './getApiOrigin';

export const handleKeepAlive = async () => {
  try {
    await axios.request({ method: 'POST', url: `${getApiOrigin()}/interactions/keepAlive`, withCredentials: true });
  } catch (e) {
    console.log(e);
  }
};
