import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import useCountDown from 'react-countdown-hook';
import { StoreContext } from '../App';
import { flowRemediation } from '../../constants/clientConfigDefaults'

const timeBase = 1000;
const counterBaseTimeMiliseconds = 6000;

export const ErrorPage: React.FC = observer(() => {
  const store = useContext(StoreContext);
  const { error, activePage } = store.AppState;
  const { flow } = store.ClientState;
  const { heading, message } = error;

  const [counter, start] = useCountDown(counterBaseTimeMiliseconds, timeBase);

  function textContent() {
    return { __html: message };
  }

  useEffect(() => {
    if (counter === 1000 && flow === flowRemediation && error.type === 'error-expired') {
      document.location.href = `https://www.americanexpress.com/nl-nl/service/identiteit/`;
    }
  }, [counter]);

  useEffect(() => {
    start(counterBaseTimeMiliseconds);
  }, []);

  return (
    <div className="page page--error">
      <div className="line centered">
        <span className="error-icon" />
      </div>
      <h1>{heading}</h1>
      <div className="line centered">
        <p className="text" dangerouslySetInnerHTML={textContent()} />
      </div>
    </div>
  );
});
