import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { StoreContext } from '../App';

export const ContinueLater: React.FC = observer(() => {
  const store = useContext(StoreContext);

  const { translations } = store.AppConfig;
  const { pageContinueLater } = translations;
  const { heading } = pageContinueLater;

  return (
    <div className="page page--continue-later">
      <div className="line centered">
        <span className="mail-icon" />
      </div>
      <h1>{heading}</h1>
    </div>
  );
});
