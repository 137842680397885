export interface StepInterface {
  step: number;
  label: string;
  status: string;
  link?: string;
}

export const steps = [
  {
    step: 1,
    label: 'applicationStart',
    status: 'done',
  },
  {
    step: 2,
    label: 'idin',
    status: '',
    link: 'idin',
  },
  {
    step: 3,
    label: 'onfido',
    status: 'disabled',
    link: 'onfido',
  },
];
