import axios from 'axios';
import getCountryIso3 from 'country-iso-2-to-3';
import { eeaMember } from 'is-european';
import { observer } from 'mobx-react';
import React, { Fragment, useContext, useEffect } from 'react';
import { onfidoLocalesNew } from '../../../constants/languages/onfidoLocalesNew';
import { steps } from '../../../constants/steps';
import { getApiOrigin } from '../../../methods/getApiOrigin';
import { StoreContext } from '../../App';

export const Onfido: React.FC = observer(() => {
  const store = useContext(StoreContext);
  const { onfidoDocuments, sdkToken, forceMobile, onfidoNew } = store.ClientState;

  const mountOnfido = (): void => {
    const onfido = (window as any).Onfido;

    const documentTypesPrep = () => {
      const output = {}
      Object.keys(onfidoDocuments).forEach((key) => {
        if (onfidoDocuments[key] === true) {
          output[key] = {
            "country": getCountryIso3(store.ClientState.selectedCountry.toUpperCase())
          }
        }
      })

      return output
    }

    const documentTypesOnfido: {
      passport: boolean;
      driving_licence: boolean;
      national_identity_card: boolean | { country: string };
      residence_permit?: boolean | { country: string };
    } = {
      passport: true,
      driving_licence: false,
      national_identity_card: getCountryIso3(store.ClientState.selectedCountry.toUpperCase())
    };

    if (store.ClientState.selectedCountry === 'NL') {
      documentTypesOnfido.residence_permit = {
        country: getCountryIso3(store.ClientState.selectedCountry.toUpperCase()),
      };
    } else {
      documentTypesOnfido.residence_permit = false;
    }

    if (eeaMember(store.ClientState.selectedCountry.toUpperCase())) {
      documentTypesOnfido.national_identity_card = {
        country: getCountryIso3(store.ClientState.selectedCountry.toUpperCase()),
      };
    } else {
      documentTypesOnfido.national_identity_card = false;
    }

    if (sdkToken) {
      console.log('documentTypesPrep:')
      console.log(documentTypesPrep())
      console.log('store.ClientState.selectedCountry')
      console.log(store.ClientState.selectedCountry)

      onfido.init({
        token: sdkToken,
        containerId: 'onfido-mount',
        steps: [
          {
            type: 'document',
            options: {
              documentTypes: documentTypesPrep(),
              forceCrossDevice: forceMobile,
            },
          },
          'complete',
        ],
        language: {
          locale: "nl_NL",
          phrases: onfidoLocalesNew.acquisition,
        },
        smsNumberCountryCode: 'NL',
        onComplete: async () => {
          try {
            const response = await axios.request({
              method: 'POST',
              url: `${getApiOrigin()}/onfido/complete`,
              withCredentials: true,
              headers: {
                'Content-Type': 'application/json',
              },
              data: {},
            });

            if (response.data) {
              store.AppState.changePage('uploadid', 4);
              store.AppState.changeStep({ step: 0, label: '', status: '' });
              store.AppState.setSteps([
                { ...steps[0], status: 'done' },
                { ...steps[1], status: 'done' },
                { ...steps[2], status: 'done' },
              ]);
            }
          } catch (e) {
            if (e.response?.status === 403) {
              store.AppState.changePageProd('error', 1);
            } else {
              console.log(e);
            }
          }
        },
        disableAnalytics: true,
        customUI: {
          "fontSizeTitle": "26px",
          "fontWeightSubtitle": 600,
          "fontSizeSubtitle": "1.25rem",
          "colorContentButtonPrimaryText": "rgb(255,255,255)",
          "colorBackgroundButtonPrimary": "rgb(39, 136, 54)",
          "colorBorderButtonPrimary": "rgb(39, 136, 54)",
          "colorBackgroundButtonPrimaryHover": "rgb(30, 98, 40)",
          "colorBackgroundButtonPrimaryActive": "rgb(67, 167, 82)",
          "borderRadiusButton": "4px",
          "colorBorderSurfaceModal": "rgb(155, 155, 155)",
          "borderRadiusSurfaceModal": "4px",
          "colorContentLinkText": "rgb(0, 0, 238)",
          "colorContentLinkTextHover": "rgb(0, 0, 238)",
          "colorBorderLinkUnderline": "rgb(0, 0, 238)",
          "colorBackgroundLinkHover": "rgb(255,255,255)",
          "colorBackgroundSurfaceModal": "rgb(255,255,255)",
          "colorContentButtonSecondaryText": "rgb(39, 136, 54)",
          "colorBackgroundButtonSecondary": "rgb(255,255,255)",
          "colorBackgroundButtonSecondaryHover": "rgb(247, 248, 249)",
          "colorBackgroundButtonSecondaryActive": "rgb(255,255,255)",
          "colorBorderButtonSecondary": "rgb(39, 136, 54)",
          "colorBorderButtonSecondaryHover": "rgb(30, 98, 40)",
          "colorBorderButtonSecondaryActive": "rgb(30, 98, 40)"
        }
      });
    }
  };

  useEffect(() => {
    mountOnfido();
  }, []);

  return (
    <Fragment>
      <div className="onfido" id="onfido-mount" />
    </Fragment>
  );
});