import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { StoreContext } from '../App';

import { footerLinks } from '../../constants/footer';
import { FooterMenu } from './footerMenu';

import './styles.scss';

export const Footer: React.FC = observer(() => {
  const store = useContext(StoreContext);

  const { translations } = store.AppConfig;
  const { footer } = translations;
  const { copyright, americanExpress, rightsReserved } = footer;

  return (
    <div className="footer">
      <div className="footer__wrapper">
        <FooterMenu items={footerLinks} />
        <span className="footer__copyright">
          {copyright}
          <a href="https://www.americanexpress.com/nl/" target="_blank" rel="noreferrer">
            {americanExpress}
          </a>
          . {rightsReserved}
        </span>
      </div>
    </div>
  );
});
