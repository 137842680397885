export const axiosConfig = (axiosInstance): object => {
  return {
    instance: axiosInstance,
    retry: 15,
    noResponseRetries: 5,
    retryDelay: 1000,
    backoffType: 'exponential',
    statusCodesToRetry: [
      [100, 199],
      [400, 429],
      [500, 599],
    ],
  };
};
