import React, { useContext, useEffect } from 'react';
import { LocalStorage } from 'combo-storage';
import { StoreContext } from '../../App';
import { default as completeCheck } from '../../../assets/complete-check.svg';

export const CompleteIdFlow: React.FC = () => {
  const store = useContext(StoreContext);
  const { translations, onfidoConfig } = store.AppConfig;
  const { showLoading } = onfidoConfig;

  const { heading, textIdin, textSkip } = translations.pageComplete;

  useEffect(() => {
    LocalStorage.remove('amex_session_counter');
    store.AppState.setActivePage('onfido-complete');
  }, []);

  return (
    <div className="page page--completed-flow">
      <img src={completeCheck} />
      <h1>{heading}</h1>
      <p className="text subtitle">{showLoading ? textIdin : textSkip}</p>
    </div>
  );
};
