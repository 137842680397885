export const nl = {
  acquisition: {
    stepsLabels: {
      applicationStart: "Aanvraagformulier verstuurd",
      idin: "Identificeren met iDIN",
      onfido: "Upload ID",
    },
    learnMore: "Meer informatie",
    helpText:
      "Heeft u nog vragen? Bel ons dan op 020 - 504 8000 en houd uw referentienummer bij de hand: ",
    cookieText: "Heeft u nog vragen? Bel ons dan op 020 - 504 8000.",
    close: "Sluiten",
    goToHomepage: "Naar American Express homepage",
    pageContinueLater: {
      heading:
        "U ontvangt binnen ongeveer 20 minuten een e-mail om uw identificatie later af te ronden",
      buttonEmail: "Geen e-mail ontvangen?",
      close: "Sluiten",
      modal: {
        heading: "Heeft u de e-mail niet ontvangen?",
        description:
          "Voer uw e-mailadres in, zodat we de e-mail opnieuw kunnen verzenden.",
        error: "Vul een geldig e-mailadres in",
        submit: "Verzenden",
        sent: "De e-mail is verzonden!",
      },
    },
    continueLabel: "Doorgaan",
    finishLaterLabel: "Later afronden",
    pageBankSelection: {
      heading: "Selecteer uw bank",
      loginToYourBank:
        "Log in bij uw bank met iDIN om uw identiteit te verifiëren.",
      pleaseChoseYourBank: "Selecteer",
      bankNotListed:
        "Overslaan: ik heb geen Nederlandse bankrekening of mijn bank staat er niet tussen.",
      modal: {
        heading: "Staat uw bank niet in de lijst?",
        text: "Met deze optie slaat u de verificatie via iDIN over en gaat u direct door naar het uploaden van een ID-document. Wij nemen op een later tijdstip per e-mail contact met u op voor additionele documentatie.",
        continue: "Doorgaan",
        goBack: "Terug naar iDIN",
      },
    },
    pageUploadIdLoading: {
      idinComplete: "iDIN afgerond",
      subtitle: "Dank u wel.",
    },
    pageUploadIdStart: {
      heading: "Upload een ID-document",
      pleaseUpload:
        "Upload een kopie van uw ID-document naar Onfido, onze betrouwbare partner voor identiteitsverificatie. Uw burgerservicenummer (BSN) wordt  automatisch afgeschermd op uw ID-document voordat deze wordt verstuurd naar American Express.",
      learnMore: "Meer informatie over Onfido",
      americanExpressDescription:
        "Meer informatie over hoe American Express of onze dienstverleners uw gegevens verzamelen, opslaan en verwerken vindt u",
      submittingDocuments: "",
      redirected: "U wordt doorgestuurd naar de website van Onfido",
      modal: {
        text: "Onfido is onze betrouwbare Dienstverlener die het mogelijk maakt ID-documenten (zoals paspoort of identiteitsbewijs) van een computer of mobiele telefoon te uploaden in een beveiligde omgeving.",
      },
      modalFinishLater: {
        heading: "Weet u zeker dat u de pagina wil verlaten?",
        paragraphFirst:
          "Indien u de identificatie nu stopt, sturen wij u een e-mail met een link om deze op een later tijdstip alsnog af te ronden. Uit veiligheidsoverwegingen verloopt deze link na 48 uur.",
        paragraphSecond: "",
        buttonComplete: "Identificatie afronden",
        buttonLeave: "Identificatie verlaten en later afronden",
      },
      pleaseWait: "Een moment geduld alstublieft.",
      pleaseWaitLonger:
        "Een moment geduld alstublieft. Het duurt iets langer dan normaal.",
    },
    pageCountrySelect: {
      heading: "Zoek het land waar mijn identiteitsbewijs is verstrekt.",
      searchCountry: "Zoek land",
      suggestedCountry: "Bedoelt u:",
      pleaseWait: "",
      pleaseWaitLonger: "",
    },
    pageComplete: {
      heading: "Gefeliciteerd, uw aanvraag is afgerond!",
      textIdin:
        "Bedankt voor het opsturen van uw informatie. Wij nemen uw aanvraag in behandeling en nemen binnen 10 werkdagen contact met u op.\n\n U mag dit scherm nu afsluiten",
      textSkip:
        "Uw aanvraag is ontvangen en wacht op beoordeling. We nemen contact met u op voor eventuele aanvullende documentatie. Je kunt dit venster sluiten.",
    },
    pageError: {
      haventRecieved: "Geen e-mail ontvangen?",
      close: "Sluiten",
    },
    idinInfoModal:
      "iDIN is een initiatief van de Nederlandse banken. Een snelle, veilige en gemakkelijke manier voor  online identificatie via de veilige en vertrouwde inlogmethode van uw eigen bank. Met iDIN worden bepaalde gegevens bevestigd die reeds bij uw bank bekend zijn, zoals uw naam, adres en geboortedatum.",
    onfidoInfoModal:
      "Onfido is onze betrouwbare Dienstverlener die het mogelijk maakt ID-documenten (zoals paspoort of identiteitsbewijs) van een computer of mobiele telefoon te uploaden in een beveiligde omgeving.",
    print: "Afdrukken",
    footer: {
      copyright: "Copyright © 2017 ",
      americanExpress: "American Express Company",
      rightsReserved:
        "Alle rechten, typefouten en prijswijzigingen voorbehouden.",
    },
    modalSessionTimer: {
      text: "Uw sessie verloopt binnenkort. Klik op Doorgaan om de applicatie terug te sturen",
      buttonComplete: "Doorgaan",
      buttonLeave: "Later afronden",
    },
    pageCountryAndDocumentSelect: {
      heading: "Selecteer het land dat uw identiteitsbewijs heeft uitgegeven.",
      searchCountry: "Zoek land",
      suggestedCountry: "Bedoelt u:",
      pleaseWait: "Even geduld aub.",
      pleaseWaitLonger: "Even geduld aub. Het kost iets meer tijd dan normaal.",
      selectDocument: "Document selecteren:",
      passport: "Paspoort",
      national_identity_card: "Nationale identiteitskaart",
      residence_permit: "Verblijfsvergunning",
      driving_licence: "Rijbewijs",
    },
  },
  remediation: {
    stepsLabels: {
      applicationStart: "Aanvraagformulier verstuurd",
      idin: "Identificeren met iDIN",
      onfido: "Upload ID",
    },
    learnMore: "Meer informatie",
    helpText: `Vragen? Bekijk <a href="https://www.americanexpress.com/nl/informatie/veilig-met-uw-kaart/index.html#faq" target="_blank" rel="noreferrer">hier</a> de Veelgestelde Vragen.`,
    cookieText: `Vragen? Bekijk <a href="https://www.americanexpress.com/nl/informatie/veilig-met-uw-kaart/index.html#faq" target="_blank" rel="noreferrer">hier</a> de Veelgestelde Vragen.`,
    close: "Sluiten",
    goToHomepage: "Naar American Express homepage",
    pageContinueLater: {
      heading:
        "U ontvangt binnen ongeveer 20 minuten een e-mail om uw identificatie later af te ronden",
      buttonEmail: "Geen e-mail ontvangen?",
      close: "Sluiten",
      modal: {
        heading: "Heeft u de e-mail niet ontvangen?",
        description:
          "Voer uw e-mailadres in, zodat we de e-mail opnieuw kunnen verzenden.",
        error: "Vul een geldig e-mailadres in",
        submit: "Verzenden",
        sent: "De e-mail is verzonden!",
      },
    },
    continueLabel: "Doorgaan",
    finishLaterLabel: "Later afronden",
    pageBankSelection: {
      heading: "Selecteer uw bank",
      loginToYourBank:
        "Log in bij uw bank met iDIN om uw identiteit te verifiëren.",
      pleaseChoseYourBank: "Selecteer",
      bankNotListed:
        "Overslaan: ik heb geen Nederlandse bankrekening of mijn bank staat er niet tussen.",
      modal: {
        heading: "Staat uw bank er niet bij?",
        text: "Dan kunt u de verificatie via iDIN overslaan en gaat u direct door naar het uploaden van een ID-document. Wij nemen later contact met u op voor additionele documentatie.",
        continue: "iDIN overslaan",
        goBack: "Terug naar iDIN",
      },
    },
    pageUploadIdLoading: {
      idinComplete: "iDIN afgerond",
      subtitle: "Dank u wel.",
    },
    pageUploadIdStart: {
      heading: "Upload een ID-document",
      pleaseUpload:
        "Upload een kopie van uw ID-document naar Onfido, onze betrouwbare partner voor identiteitsverificatie. Uw burgerservicenummer (BSN) wordt  automatisch afgeschermd op uw ID-document voordat deze wordt verstuurd naar American Express.",
      learnMore: "Meer informatie over Onfido",
      americanExpressDescription:
        "Meer informatie over hoe American Express of onze dienstverleners uw gegevens verzamelen, opslaan en verwerken vindt u",
      submittingDocuments: "",
      redirected: "U wordt doorgestuurd naar de website van Onfido",
      modal: {
        text: "Onfido is onze betrouwbare Dienstverlener die het mogelijk maakt ID-documenten (zoals paspoort of identiteitsbewijs) van een computer of mobiele telefoon te uploaden in een beveiligde omgeving.",
      },
      modalFinishLater: {
        heading: "Weet u zeker dat u de pagina wil verlaten?",
        paragraphFirst:
          "Indien u de identificatie nu stopt, sturen wij u een e-mail met een link om deze op een later tijdstip alsnog af te ronden. Uit veiligheidsoverwegingen verloopt deze link na 48 uur.",
        paragraphSecond: "",
        buttonComplete: "Identificatie afronden",
        buttonLeave: "Identificatie verlaten en later afronden",
      },
      pleaseWait: "",
      pleaseWaitLonger: "",
    },
    pageCountrySelect: {
      heading: "Selecteer het land dat uw identiteitsbewijs heeft uitgegeven.",
      searchCountry: "Zoek land",
      suggestedCountry: "Bedoelt u:",
      pleaseWait: "Even geduld aub.",
      pleaseWaitLonger: "Even geduld aub. Het kost iets meer tijd dan normaal.",
    },
    pageComplete: {
      heading: "Gefeliciteerd, uw aanvraag is afgerond!",
      textIdin:
        "Bedankt voor het opsturen van uw informatie. Wij nemen uw aanvraag in behandeling en nemen binnen 10 werkdagen contact met u op.\n\n U mag dit scherm nu afsluiten",
      textSkip:
        "Uw aanvraag is ontvangen en wacht op beoordeling. We nemen contact met u op voor eventuele aanvullende documentatie. Je kunt dit venster sluiten.",
    },
    pageError: {
      haventRecieved: "Geen e-mail ontvangen?",
      close: "Sluiten",
    },
    idinInfoModal:
      "iDIN is een initiatief van de Nederlandse banken. Een snelle, veilige en gemakkelijke manier voor online identificatie via de vertrouwde inlogmethode van uw eigen bank. Met iDIN worden bepaalde gegevens bevestigd die reeds bij uw bank bekend zijn, zoals uw naam, adres en geboortedatum.",
    onfidoInfoModal:
      "Onfido is een betrouwbare dienstverlener die het mogelijk maakt in een beveiligde omgeving, ID-documenten (zoals paspoort of identiteitskaart) van een computer of mobiele telefoon te uploaden.",
    print: "Afdrukken",
    footer: {
      copyright: "Copyright © 2020 ",
      americanExpress: "American Express Company",
      rightsReserved:
        "Alle rechten, typefouten en prijswijzigingen voorbehouden.",
    },
    modalSessionTimer: {
      text: "Uw sessie verloopt bijna.",
      buttonComplete: "Vervolg de sessie",
      buttonLeave: "Later afronden",
    },
    pageCountryAndDocumentSelect: {
      heading: "Selecteer het land dat uw identiteitsbewijs heeft uitgegeven.",
      searchCountry: "Zoek land",
      suggestedCountry: "Bedoelt u:",
      pleaseWait: "Even geduld aub.",
      pleaseWaitLonger: "Even geduld aub. Het kost iets meer tijd dan normaal.",
      selectDocument: "Document selecteren:",
      passport: "Paspoort",
      national_identity_card: "Nationale identiteitskaart",
      residence_permit: "Verblijfsvergunning",
      driving_licence: "Rijbewijs",
    },
  },
};
