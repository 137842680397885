export interface PageInterface {
  page: string;
  subpage: number;
  isLoaded: boolean;
}

export const pages = {
  pageBankSelection: { page: 'bank', subpage: 1, isLoaded: true },
  pageContinue: { page: 'continue', subpage: 1, isLoaded: true },
  pageUploadIdStart: { page: 'uploadid', subpage: 1, isLoaded: true },
  pageCountrySelect: { page: 'uploadid', subpage: 2, isLoaded: true },
  pageOnfido: { page: 'uploadid', subpage: 3, isLoaded: true },
  pageFinish: { page: 'uploadid', subpage: 4, isLoaded: true },
  pageError: { page: 'error', subpage: 1, isLoaded: true },
};
