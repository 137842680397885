import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { LocalStorage } from 'combo-storage';
import axios from 'axios';

import { StoreContext } from '../App';
import { Modal } from './component';
import { getCurrentTime } from '../../methods/getCurrentTime';
import { getApiOrigin } from '../../methods/getApiOrigin';

export const FinishLaterModal: React.FC = observer(() => {
  const store = useContext(StoreContext);
  const { translations, finishLaterVisible } = store.AppConfig;
  const { changePage, setActivePage } = store.AppState;
  const { pageUploadIdStart } = translations;
  const { modalFinishLater } = pageUploadIdStart;

  const resetStartTime = (): void => {
    const currentTime = getCurrentTime();
    LocalStorage.set('amex_session_counter', currentTime);
    store.AppConfig.setSessionStartTime(currentTime);
  };

  const handleFinishLater = async (): Promise<void> => {
    try {
      await axios.request({
        method: 'POST',
        url: `${getApiOrigin()}/interactions/finishLater`,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {},
      });
      store.AppConfig.setFinishLaterVisible(false);
      resetStartTime();
      setActivePage('continue');
      changePage('continue', 1);
    } catch (e) {
      if (e.response?.status === 409) {
        changePage('error', 1);
      } else {
        console.log(e);
      }
    }
  };

  const handleContinue = (): void => {
    resetStartTime();
    store.AppConfig.setFinishLaterVisible(false);
  };

  const handleModalVisibility = (): void => {
    store.AppConfig.setFinishLaterVisible(false);
  };

  return (
    <Modal modalVisible={finishLaterVisible} setModalVisible={handleModalVisibility}>
      <div className="modal--finish-later-onfido">
        <div className="line centered">
          <h2>{modalFinishLater.heading}</h2>
          <p>{modalFinishLater.paragraphFirst}</p>
          <p>{modalFinishLater.paragraphSecond}</p>
          <button className="btn btn-primary" onClick={(): void => handleContinue()}>
            {modalFinishLater.buttonComplete}
          </button>
          <button className="btn btn--bordered" onClick={(): Promise<void> => handleFinishLater()}>
            {modalFinishLater.buttonLeave}
          </button>
        </div>
      </div>
    </Modal>
  );
});
