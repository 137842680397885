import { action, makeAutoObservable } from 'mobx';
import { nl } from '../constants/languages/nl';
import { TranslationsInterface } from '../constants/languages/interface';

export class AppConfigStore {
  rootStore;
  browserLanguage: string = window.navigator['userLanguage'] || window.navigator.language; // IE fallback: userLanguage
  sessionStartTime = '';
  finishLaterVisible = false;
  onfidoConfig = {
    documentTypes: {
      passport: true,
      // eslint-disable-next-line @typescript-eslint/camelcase
      driving_licence: false,
      // eslint-disable-next-line @typescript-eslint/camelcase
      national_identity_card: true,
    },
    showLoading: true,
  };
  translations: TranslationsInterface = nl.acquisition;

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound setLanguage(languageSet: TranslationsInterface): void {
    this.translations = languageSet;
  }

  @action.bound setSessionStartTime(sessionStartTime: string): void {
    this.sessionStartTime = sessionStartTime;
  }

  @action.bound setFinishLaterVisible(finishLaterVisible: boolean): void {
    this.finishLaterVisible = finishLaterVisible;
  }

  @action.bound setOnfidoShowLoading(showLoading: boolean): void {
    this.onfidoConfig.showLoading = showLoading;
  }

  @action.bound setOnfidoConfig(config: {
    documentTypes: { passport: boolean; driving_licence: boolean; national_identity_card: boolean };
    showLoading: boolean;
  }): void {
    this.onfidoConfig = config;
  }
}
