import React from 'react';

type FooterMenuItemType = {
  label: string;
  url: string;
};

type FooterMenuTypes = {
  items: FooterMenuItemType[];
};

export const FooterMenu: React.FC<FooterMenuTypes> = ({ items }: FooterMenuTypes) => {
  return (
    <ul className="footer__menu">
      {items.map((item) => (
        <li key={item.label}>
          <a href={item.url} target="_blank" rel="noreferrer">
            {item.label}
          </a>
        </li>
      ))}
    </ul>
  );
};
