import React, { useContext } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import { BankSelection } from './bankSelection';
import { UploadId } from './uploadid/component';
import { ContinueLater } from './continueLater';
import { ErrorPage } from './errorPage';
import { StoreContext } from '../App';

import './styles.scss';

export const Content: React.FC = observer(() => {
  const store = useContext(StoreContext);
  const { currentScreen } = store.AppState;

  const { page, subpage } = currentScreen;

  return (
    <div className={classnames('content-wrapper', page === 'error' ? 'content-wrapper--margin-top' : '')}>
      <div className={`content content--${page}-${subpage}`}>
        {page === 'bank' && <BankSelection />}
        {page === 'uploadid' && <UploadId />}
        {page === 'continue' && <ContinueLater />}
        {page === 'error' && <ErrorPage />}
      </div>
    </div>
  );
});
