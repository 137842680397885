import { action, makeAutoObservable, reaction } from 'mobx';
import { DocumentTypeInterface } from '../components/documentSelector/component';
import { flowDefault, flowRemediation } from '../constants/clientConfigDefaults';
import { countries } from '../constants/countries';
import { nl } from '../constants/languages/nl';

export class ClientStateStore {
  rootStore;
  selectedCountry = '';
  selectedDocument = '';
  onfidoDocuments = {};
  sdkToken = '';
  attemptId = '';
  webHook = '';
  pcn = '';
  clic = '';
  flow = flowDefault;
  redirectUri = '';
  forceMobile = false;
  onfidoNew = '0';

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;

    reaction(
      () => this.selectedCountry,
      () => {
        if (this.selectedCountry.length) {
          const country = countries.find((country) => country.id.toLowerCase() === this.selectedCountry.toLowerCase());
          this.rootStore.AppConfig.setOnfidoConfig({
            ...this.rootStore.AppConfig.onfidoConfig,
            documentTypes: country.documentTypes,
          });
        }
      },
    );
  }

  @action.bound setSelectedCountry(country: string): void {
    this.selectedCountry = country;
  }

  @action.bound setSelectedDocument(document: DocumentTypeInterface): void {
    this.selectedCountry = document;
  }

  @action.bound setOnfidoDocuments(documents: {}): void {
    this.onfidoDocuments = documents;
  }

  @action.bound setSdkToken(sdkToken: string): void {
    this.sdkToken = sdkToken;
  }

  @action.bound setAttemptId(attemptId: string): void {
    this.attemptId = attemptId;
  }

  @action.bound setWebHook(webHook: string): void {
    this.webHook = webHook;
  }

  @action.bound setPcn(pcn: string): void {
    this.pcn = pcn;
  }

  @action.bound setClic(clic: string): void {
    this.clic = clic;
  }

  @action.bound setFlow(flow: string): void {
    this.flow = flow;

    if (flow === flowDefault) {
      this.rootStore.AppConfig.setLanguage(nl.acquisition);
    }

    if (flow === flowRemediation) {
      this.rootStore.AppConfig.setLanguage(nl.remediation);
    }
  }

  @action.bound setRedirectUri(redirectUri: string): void {
    this.redirectUri = redirectUri;
  }

  @action.bound setForceMobile(forceMobile: boolean): void {
    this.forceMobile = forceMobile;
  }

  @action.bound setOnfidoNew(onfidoNew: string): void {
    this.onfidoNew = onfidoNew;
  }
}
