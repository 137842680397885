/* eslint-disable @typescript-eslint/camelcase */
export interface CountryInterface {
  name: string;
  id: string;
  documentTypes: {
    passport: boolean;
    driving_licence: boolean;
    national_identity_card: boolean;
  };
}

export const countries = [
  {
    name: 'Afghanistan',
    id: 'AF',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Albanië',
    id: 'AL',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Algerije',
    id: 'DZ',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Andorra',
    id: 'AD',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Angola',
    id: 'AO',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Anguilla',
    id: 'AI',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Antigua en Barbuda',
    id: 'AG',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Argentinië',
    id: 'AR',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Armenië',
    id: 'AM',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Australië',
    id: 'AU',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Azerbeidzjan',
    id: 'AZ',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: "Bahama's",
    id: 'BS',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Bahrein',
    id: 'BH',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Bangladesh',
    id: 'BD',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Barbados',
    id: 'BB',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'België',
    id: 'BE',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'Belize',
    id: 'BZ',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Benin',
    id: 'BJ',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Bermuda',
    id: 'BM',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Bhutan',
    id: 'BT',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Bolivia',
    id: 'BO',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Bosnië - Herzegovina',
    id: 'BA',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Botswana',
    id: 'BW',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Brazilië',
    id: 'BR',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Britse Maagdeneilanden',
    id: 'VG',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Brunei',
    id: 'BN',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Bulgarije',
    id: 'BG',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'Burkina Faso',
    id: 'BF',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Burundi',
    id: 'BI',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Cambodja',
    id: 'KH',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Canada',
    id: 'CA',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Centraal-Afrikaanse Republiek',
    id: 'CF',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Chili',
    id: 'CL',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'China',
    id: 'CN',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Colombia',
    id: 'CO',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Comoren',
    id: 'KM',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Congo (Democratische Republiek)',
    id: 'CD',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Congo-Brazzaville',
    id: 'CG',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Costa Rica',
    id: 'CR',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Cuba',
    id: 'CU',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Cyprus',
    id: 'CY',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'Denemarken',
    id: 'DK',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Djibouti',
    id: 'DJ',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Dominica',
    id: 'DM',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Dominicaanse Republiek',
    id: 'DO',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Duitsland',
    id: 'DE',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'Ecuador',
    id: 'EC',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Egypte',
    id: 'EG',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'El Salvador',
    id: 'SV',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Equatoriaal-Guinea',
    id: 'GQ',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Eritrea',
    id: 'ER',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Estland',
    id: 'EE',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'Ethiopië',
    id: 'ET',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Faeröer Eilanden',
    id: 'FO',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Fiji',
    id: 'FJ',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Filipijnen',
    id: 'PH',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Finland',
    id: 'FI',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'Frankrijk',
    id: 'FR',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'Gabon',
    id: 'GA',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Gambia',
    id: 'GM',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Georgië',
    id: 'GE',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Ghana',
    id: 'GH',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Gibraltar',
    id: 'GI',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Grenada',
    id: 'GD',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Griekenland',
    id: 'GR',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'Guatemala',
    id: 'GT',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Guernsey',
    id: 'GG',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Guinee',
    id: 'GN',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Guinee-Bissau',
    id: 'GW',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Guyana',
    id: 'GY',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Haïti',
    id: 'HT',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Het eiland Man',
    id: 'IM',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Honduras',
    id: 'HN',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Hong Kong',
    id: 'HK',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Hongarije',
    id: 'HU',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'Ierland',
    id: 'IE',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'IJsland',
    id: 'IS',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'India',
    id: 'IN',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Indonesië',
    id: 'ID',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Irak',
    id: 'IQ',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Iran',
    id: 'IR',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Israël',
    id: 'IL',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Italië',
    id: 'IT',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'Ivoorkust',
    id: 'CI',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Jamaica',
    id: 'JM',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Japan',
    id: 'JP',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Jemen',
    id: 'YE',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Jersey',
    id: 'JE',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Jordanië',
    id: 'JO',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Kaaimaneilanden',
    id: 'KY',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Kaapverdië',
    id: 'CV',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Kameroen',
    id: 'CM',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Kazachstan',
    id: 'KZ',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Kenia',
    id: 'KE',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Kirgizië',
    id: 'KG',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Kiribati',
    id: 'KI',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Koeweit',
    id: 'KW',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Kosovo',
    id: 'XK',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Kroatië',
    id: 'HR',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'Laos',
    id: 'LA',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Lesotho',
    id: 'LS',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Letland',
    id: 'LV',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'Libanon',
    id: 'LB',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Liberia',
    id: 'LR',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Libië',
    id: 'LY',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Liechtenstein',
    id: 'LI',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'Litouwen',
    id: 'LT',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'Luxemburg',
    id: 'LU',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'Madagaskar',
    id: 'MG',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Malawi',
    id: 'MW',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Maldiven',
    id: 'MV',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Maleisië',
    id: 'MY',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Mali',
    id: 'ML',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Malta',
    id: 'MT',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'Marokko',
    id: 'MA',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Marshalleilanden',
    id: 'MH',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Mauritanië',
    id: 'MR',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Mauritius',
    id: 'MU',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Mexico',
    id: 'MX',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Micronesië',
    id: 'FM',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Moldavië',
    id: 'MD',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Monaco',
    id: 'MC',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Mongolië',
    id: 'MN',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Montenegro',
    id: 'ME',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Montserrat',
    id: 'MS',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Mozambique',
    id: 'MZ',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Myanmar',
    id: 'MM',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Namibië',
    id: 'NA',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Nauru',
    id: 'NR',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Nederland',
    id: 'NL',
    documentTypes: {
      passport: true,
      driving_licence: true,
      national_identity_card: true,
    },
  },
  {
    name: 'Nepal',
    id: 'NP',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Nicaragua',
    id: 'NI',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Nieuw-Zeeland',
    id: 'NZ',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Niger',
    id: 'NE',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Nigeria',
    id: 'NG',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Noord-Korea',
    id: 'KP',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Noord-Macedonië',
    id: 'MK',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Noorwegen',
    id: 'NO',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Oeganda',
    id: 'UG',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Oekraïne',
    id: 'UA',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Oezbekistan',
    id: 'UZ',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Oman',
    id: 'OM',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Oostenrijk',
    id: 'AT',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'Oost-Timor',
    id: 'TL',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Pakistan',
    id: 'PK',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Palau',
    id: 'PW',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Palestina',
    id: 'PS',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Panama',
    id: 'PA',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Papoea-Nieuw-Guinea',
    id: 'PG',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Paraguay',
    id: 'PY',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Peru',
    id: 'PE',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Polen',
    id: 'PL',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'Portugal',
    id: 'PT',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'Puerto Rico',
    id: 'PR',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Qatar',
    id: 'QA',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Roemenië',
    id: 'RO',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'Rusland',
    id: 'RU',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Rwanda',
    id: 'RW',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Saint Kitts en Nevis',
    id: 'KN',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Saint Vincent en The Grenadines',
    id: 'VC',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Saint-Lucia',
    id: 'LC',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Salomonseilanden',
    id: 'SB',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'San Marino',
    id: 'SM',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Sao Tomé en Principe',
    id: 'ST',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Saoedi-Arabië',
    id: 'SA',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Senegal',
    id: 'SN',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Servië',
    id: 'RS',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Seychellen',
    id: 'SC',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Sierra Leone',
    id: 'SL',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Singapor',
    id: 'SG',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Slovenië',
    id: 'SI',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'Slowakije',
    id: 'SK',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'Soedan',
    id: 'SD',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Somalië',
    id: 'SO',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Somoa',
    id: 'WS',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Spanje',
    id: 'ES',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'Sri Lanka',
    id: 'LK',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Suriname',
    id: 'SR',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Swaziland',
    id: 'SZ',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Syrië',
    id: 'SY',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Tadzjikistan',
    id: 'TJ',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Taiwan',
    id: 'TW',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Tanzania',
    id: 'TZ',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Thailand',
    id: 'TH',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Togo',
    id: 'TG',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Tonga',
    id: 'TO',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Trinidad en Tobago',
    id: 'TT',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Tsjaad',
    id: 'TD',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Tsjechië',
    id: 'CZ',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'Tunesië',
    id: 'TN',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Turkije',
    id: 'TR',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Turkmenistan',
    id: 'TM',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Turks- en Caicoseilanden',
    id: 'TC',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Tuvalu',
    id: 'TV',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Uruguay',
    id: 'UY',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Vaticaanstad',
    id: 'VA',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Venezuela',
    id: 'VE',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Venuatu',
    id: 'VU',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Verenigd Koninkrijk',
    id: 'GB',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Verenigde Arabische Emiraten',
    id: 'AE',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Verenigde Staten van Amerika',
    id: 'US',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Vietnam',
    id: 'VN',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Wit-Rusland',
    id: 'BY',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Zambia',
    id: 'ZM',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Zimbabwe',
    id: 'ZW',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Zuid-Afrika',
    id: 'ZA',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Zuid-Korea',
    id: 'KR',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Zuid-Soedan',
    id: 'SS',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
  {
    name: 'Zweden',
    id: 'SE',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: true,
    },
  },
  {
    name: 'Zwisterland',
    id: 'CH',
    documentTypes: {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
    },
  },
];
