import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useCountDown from 'react-countdown-hook';
import { LocalStorage } from 'combo-storage';
import moment from 'moment';

import { StoreContext } from '../App';
import { Modal } from './component';
import { getApiOrigin } from '../../methods/getApiOrigin';
import { getCurrentTime, addMinutes } from '../../methods/getCurrentTime';
import { handleKeepAlive } from '../../methods/handleKeepAlive';
import { flowDefault, flowRemediation } from '../../constants/clientConfigDefaults'

const timeBase = 1000;
const counterBaseTimeMinutes = 20;
const counterBaseTimeMiliseconds = 1200000;
const showCounterTime = 60000;

export const SessionModal: React.FC = observer(() => {
  const [counter, start] = useCountDown(counterBaseTimeMiliseconds, timeBase);
  const [modalVisible, setModalVisible] = useState(false);
  const [countdownStart, setCountdownStart] = useState(false);

  const store = useContext(StoreContext);
  const { translations, sessionStartTime } = store.AppConfig;
  const { currentScreen, activePage } = store.AppState;
  const { flow } = store.ClientState;
  const { page, subpage } = currentScreen;
  const { modalSessionTimer } = translations;

  const handleRedirect = (): void => {
    if ((page !== 'error' && activePage !== 'onfido-complete' && page !== 'continue') || subpage !== 4) {
      window.location.href = `${getApiOrigin()}/session/expire`;
    }
  };

  const restart = React.useCallback(
    (newTime: number) => {
      start(newTime);
    },
    [start],
  );

  const handleSavedSessionTime = (): void => {
    const savedTime = sessionStartTime;
    const currentTime = getCurrentTime();
    const counterEndDate = addMinutes(new Date(savedTime), counterBaseTimeMinutes);
    const counterExpired = Date.parse(counterEndDate.toString()) < Date.parse(currentTime);

    if (!counterExpired) {
      const durationLeft = moment.duration(moment(counterEndDate).diff(moment(new Date())));
      restart(durationLeft.asMilliseconds());
      setCountdownStart(true);
    } else {
      handleRedirect();
    }
  };

  const handleVisibleCounter = (): string => {
    const minutes = Math.floor(counter / 1000 / 60);
    const seconds = Math.floor(counter / 1000) % 60;

    return `${minutes > 9 ? minutes : '0' + minutes}:${seconds > 9 ? seconds : '0' + seconds}`;
  };

  const handleModalCloseByUser = (modalVisibility: boolean): void => {
    setModalVisible(modalVisibility);
  };

  const resetStartTime = (): void => {
    const currentTime = getCurrentTime();
    LocalStorage.set('amex_session_counter', currentTime);
    store.AppConfig.setSessionStartTime(currentTime);
    handleSavedSessionTime();
    setModalVisible(false);
  };

  const handleContinue = (): void => {
    resetStartTime();
    flow === flowRemediation && handleKeepAlive();
  };

  const handleFinishLater = (): void => {
    handleModalCloseByUser(false);
    store.AppConfig.setFinishLaterVisible(true);
    resetStartTime();
  };

  useEffect(() => {
    sessionStartTime && handleSavedSessionTime();
  }, [sessionStartTime]);

  useEffect(() => {
    if (
      counter < showCounterTime &&
      counter !== 0 &&
      !modalVisible &&
      page !== 'error' &&
      activePage !== 'onfido-complete' &&
      page !== 'session-expired' &&
      page !== 'error-auth' &&
      page !== 'continue' &&
      subpage !== 4
    ) {
      setModalVisible(true);
    }

    if (counter >= showCounterTime) {
      setModalVisible(false);
    }

    if (counter <= timeBase && countdownStart) {
      handleRedirect();
    }
  }, [counter]);

  return (
    <Modal modalVisible={modalVisible} setModalVisible={(modalVisibility: boolean): void => handleModalCloseByUser(modalVisibility)}>
      <div className="session-timer-modal">
        <p>{modalSessionTimer.text}</p>
        <span>{handleVisibleCounter()}</span>
        <button className="btn btn-primary" onClick={(): void => handleContinue()}>
          {modalSessionTimer.buttonComplete}
        </button>
        {flow === flowDefault ? (
          <button className="btn btn--bordered" onClick={(): void => handleFinishLater()}>
            {modalSessionTimer.buttonLeave}
          </button>
        ) : (
          ''
        )}
      </div>
    </Modal>
  );
});
