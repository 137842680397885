import axios from 'axios';
import { eeaMember } from 'is-european';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import * as rax from 'retry-axios';
import { default as onfidoLogo } from '../../../assets/onfido_logo.svg';
import { axiosConfig } from '../../../constants/axiosConfig';
import { flowDefault, flowRemediation } from '../../../constants/clientConfigDefaults';
import { countries } from '../../../constants/countries';
import { getApiOrigin } from '../../../methods/getApiOrigin';
import { StoreContext } from '../../App';
import { DocumentSelector } from '../../documentSelector/component';
import { SuggestInput } from '../../suggestInput/component';

const defaultSuggest = {
  name: 'Nederland',
  id: 'NL',
  documentTypes: {
    passport: true,
    // eslint-disable-next-line @typescript-eslint/camelcase
    driving_licence: false,
    // eslint-disable-next-line @typescript-eslint/camelcase
    national_identity_card: true,
  },
};

export const CountryAndDocument: React.FC = observer(() => {
  const onfido = (window as any).Onfido;
  const store = useContext(StoreContext);
  const { translations } = store.AppConfig;
  const { selectedCountry, selectedDocument, flow, sdkToken, onfidoNew } = store.ClientState;

  const [showedDocuments, setShowedDocuments] = useState({
    passport: true,
    driving_licence: false,
    national_identity_card: false,
    residence_permit: false
  })

  const { continueLabel } = translations;
  const {
    heading,
    searchCountry,
    suggestedCountry,
    pleaseWait,
    pleaseWaitLonger,
  } = translations.pageCountryAndDocumentSelect;

  const [spinnerText, setSpinnerText] = useState(pleaseWait);

  const axiosInstance = axios.create();
  axiosInstance.defaults.raxConfig = {
    ...axiosConfig(axiosInstance),
    onRetryAttempt: (err): void => {
      const cfg = rax.getConfig(err);
      if (cfg.currentRetryAttempt === 5) {
        store.AppState.changePageProd('error', 1);
      }
    },
  };
  const interceptorId = rax.attach(axiosInstance);

  const getOnfidoToken = async (): Promise<string> => {
    try {
      const response = await axiosInstance.request({
        method: 'GET',
        url: `${getApiOrigin()}/onfido/token`,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {},
      });

      return response.data.onfidoSdkToken;
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnfidoToken = async (): Promise<void> => {
    try {
      const token = await getOnfidoToken();

      if (token && token.length) {
        store.ClientState.setSdkToken(token);
      }
    } catch (e) {
      store.AppState.changePageProd('error', 1);
      console.log(e);
    }
  };

  const handlePageChange = (event: React.MouseEvent): void => {
    event.preventDefault();
    store.AppState.changePage('uploadid', 3);
  };

  const validateCurrentSelection = (): boolean => {
    const validator = countries.filter((country) => country.id === selectedCountry);
    return !!validator.length;
  };

  function handleAvaliableDocuments(countryId: string) {
    const output = {
      passport: true,
      driving_licence: false,
      national_identity_card: false,
      residence_permit: false
    }

    if (countryId.toUpperCase() === 'NL') {
      output.residence_permit = true
    } else {
      output.residence_permit = false
    }

    if (eeaMember(countryId.toUpperCase())) {
      output.national_identity_card = true
    } else {
      output.national_identity_card = false
    }

    setShowedDocuments(output)
  }

  function handleCountrySelect(countryId: string) {
    store.ClientState.setSelectedCountry(countryId)
    handleAvaliableDocuments(countryId)
  }

  useEffect(() => {
    validateCurrentSelection()
  }, [selectedCountry]);

  useEffect(() => {
    store.ClientState.flow === flowRemediation ? handleOnfidoToken() : '';

    // setTimeout(() => {
    //   setSpinnerText(pleaseWaitLonger)
    //   clearTimeout(this)
    // }, 30000)
  }, []);

  return (
    <div className="page page--country-select">
      {flow !== flowDefault ? (
        <div className="onfido-logo--container">
          <img className="onfido-logo--image" src={onfidoLogo} />
        </div>
      ) : (
        ''
      )}
      <div>
        <h1>{onfidoNew && onfidoNew === '1' ? 'Zoek het land waar uw identiteitsbewijs is verstrekt.' : heading}</h1>
        <div className="country-select--wrapper">
          <SuggestInput
            values={countries}
            onChange={(countryId: string): void => handleCountrySelect(countryId)}
            defaultSuggest={defaultSuggest}
            placeholder={searchCountry}
            suggestLabel={suggestedCountry}
          />
          {selectedCountry.length ? (
            <DocumentSelector
              selection={selectedDocument}
              setSelection={(selection) => {
                store.ClientState.setOnfidoDocuments(selection)
              }}
              showedDocuments={showedDocuments}
            />
          ) : (<div />)}
          {onfido === undefined || !sdkToken ? (
            <div className="onfido-token__holder">
              <div className="onfido-token-spinner" />
              {/* <div className="redirect-info">{spinnerText}</div> */}
            </div>
          ) : (
            <div className="liner centered">
              <button className="btn" onClick={handlePageChange} disabled={!validateCurrentSelection()}>
                {continueLabel}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
