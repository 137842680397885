export const footerLinks = [
  {
    url: 'https://www.americanexpress.com/nl/rules-and-regulations.html?inav=nl_legalfooter_rulesandreg',
    label: 'website regels en voorschriften',
  },
  {
    url: 'https://www.americanexpress.com/nl/amex-trademarks.html?inav=nl_legalfooter_trademarks',
    label: 'handelsmerken',
  },
  {
    url: 'https://www.americanexpress.com/nl/legal/online-privacyverklaring.html',
    label: 'privacy verklaring',
  },
];
