import React, { createContext, useEffect } from 'react';
import 'mobx-react-lite';
import { LocalStorage } from 'combo-storage';
import { arrive, leave } from 'arrive';

import { rootStore } from '../stores/Root.store';

import { Topbar } from './topbar/component';
import { Steps } from './steps/component';
import { Content } from './content/component';
import { Help } from './help/component';
import { Footer } from './footer/component';
import { SessionModal } from './modal/sessionModal';
import { FinishLaterModal } from './modal/finishLaterModal';

import { getQueryVariable } from '../methods/getQueryVariable';
import { getCurrentTime } from '../methods/getCurrentTime';
import { handleKeepAlive } from '../methods/handleKeepAlive';
import { onElementReady } from '../methods/onElementReady';
import { flowDefault } from '../constants/clientConfigDefaults'

import { onfidoMockedButton } from '../constants/onfidoMockedButton';
import { onfidoFullLegal } from '../constants/onfidoFullLegal';

import './app.scss';

export const StoreContext = createContext(rootStore);

declare global {
  interface Document {
    arrive: any;
    leave: any;
  }
}

export const App: React.FC = () => {
  const obs = new MutationObserver(() => {
    const mainContainer = document.getElementsByClassName('onfido-sdk-ui-Theme-content')[0];

    obs.disconnect();
    const selectionList = document.getElementsByClassName('onfido-sdk-ui-DocumentSelector-list');
    if (selectionList && selectionList[0]) {
      if (rootStore.ClientState.selectedCountry.toUpperCase() === 'NL') {
        selectionList[0].insertAdjacentHTML('beforeend', onfidoMockedButton);
      }
      document.getElementsByClassName('onfido-sdk-ui-Theme-footer')[0].insertAdjacentHTML('beforeend', onfidoFullLegal);
    }

    const uploaderContainer = document.getElementsByClassName('onfido-sdk-ui-Uploader-container');
    if (uploaderContainer && uploaderContainer[0]) {
      const legalInfoElement = document.getElementsByClassName('onfido-full-legal');
      if (legalInfoElement && legalInfoElement[0]) {
        legalInfoElement[0].remove();
      }
    }

    const mobileFlowContainer = document.getElementsByClassName('onfido-sdk-ui-crossDevice-Intro-container');
    if (mobileFlowContainer && mobileFlowContainer[0]) {
      const element = document.getElementsByClassName('onfido-full-legal')[0];
      if (element) element.remove();
    }

    const completeWrapper = document.getElementsByClassName('onfido-sdk-ui-Complete-wrapper');
    if (completeWrapper && completeWrapper[0]) {
      const element = document.getElementsByClassName('onfido-sdk-ui-Theme-footer')[0];
      if (element) element.insertAdjacentHTML('beforeend', onfidoFullLegal);
    }

    obs.observe(mainContainer, { subtree: true, childList: true });
  });

  const populateOnfidoElements = () => {
    const onfidoNew = getQueryVariable('onfido_new');
    const flow = getQueryVariable('flow');
    if (flow && flow === flowDefault && onfidoNew === '1') {
      onElementReady('.onfido-sdk-ui-Theme-content').then((selector) => {
        const selectionList = document.getElementsByClassName('onfido-sdk-ui-DocumentSelector-list');

        if (selectionList && selectionList[0]) {
          if (rootStore.ClientState.selectedCountry.toUpperCase() === 'NL') {
            selectionList[0].insertAdjacentHTML('beforeend', onfidoMockedButton);
          }
          document.getElementsByClassName('onfido-sdk-ui-Theme-footer')[0].insertAdjacentHTML('beforeend', onfidoFullLegal);
        }

        obs.observe(selector, { subtree: true, childList: true });
      });
    }
  };

  useEffect(() => {
    // Get current step and page
    const flow = getQueryVariable('flow');
    flow && flow.length > 0 && rootStore.ClientState.setFlow(flow);

    const activePage = getQueryVariable('step');
    activePage && rootStore.AppState.setActivePage(activePage);

    const webhookUrl = getQueryVariable('webhook_url');
    webhookUrl && rootStore.ClientState.setWebHook(webhookUrl);

    const queryPcn = getQueryVariable('pcn');
    queryPcn && rootStore.ClientState.setPcn(decodeURIComponent(queryPcn));

    const queryClic = getQueryVariable('clic');
    queryClic && rootStore.ClientState.setClic(decodeURIComponent(queryClic));

    const forceMobile = getQueryVariable('force_mobile');
    forceMobile && forceMobile.length > 0 && rootStore.ClientState.setForceMobile(true);

    const onfidoNew = getQueryVariable('onfido_new');
    onfidoNew && onfidoNew === '1' && rootStore.ClientState.setOnfidoNew(onfidoNew);

    // handle localStorage session counter
    const currentTime = getCurrentTime();
    LocalStorage.set('amex_session_counter', currentTime);
    rootStore.AppConfig.setSessionStartTime(currentTime);
    flow && flow === flowDefault && handleKeepAlive();
  }, []);

  useEffect(() => {
    populateOnfidoElements();
  }, [rootStore.AppState.activePage]);

  return (
    <StoreContext.Provider value={rootStore}>
      <div className="app">
        <Topbar />
        <Steps />
        <Content />
        <Help />
        <Footer />
        <SessionModal />
        <FinishLaterModal />
      </div>
    </StoreContext.Provider>
  );
};
