import { AppStateStore } from './AppStateStore';
import { AppConfigStore } from './AppConfigStore';
import { ClientStateStore } from './ClientStateStore';

export class RootStore {
  AppState: AppStateStore;
  AppConfig: AppConfigStore;
  ClientState: ClientStateStore;

  constructor() {
    this.AppState = new AppStateStore(this);
    this.AppConfig = new AppConfigStore(this);
    this.ClientState = new ClientStateStore(this);
  }
}

export const rootStore = new RootStore();
