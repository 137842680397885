export const getApiOrigin = (): string => {
  const currentUrl = window.location.origin;
  const schemaLenght = 'https://'.length;
  const authorityFirstPart = currentUrl.split('.')[0].slice(schemaLenght); //eg. sandbox-web
  const domainDetails = authorityFirstPart.substr(0, authorityFirstPart.search('web')); //eg. sandbox-

  let output = `https://${domainDetails}web-api.attempt.signicat.io`;

  return output;
};
