import axios from 'axios';
import { getApiOrigin } from './getApiOrigin';

export const handleFinishLater = async () => {
  try {
    await axios.post(`${getApiOrigin()}/interactions/finishLater`, {});
  } catch (e) {
    console.log(e);
  }
};
