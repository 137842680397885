/* eslint-disable @typescript-eslint/camelcase */
export const onfidoLocalesNew = {
  acquisition: {
    country_select: {
      alert_dropdown: {
        country_not_found: 'Land niet gevonden',
      },
      alert: {
        another_doc: 'Documenten uit dat land worden momenteel niet ondersteund — <fallback>probeer een ander document type</fallback>',
      },
      button_primary: 'Verzend document',
      search: {
        accessibility: 'Selecteer land',
        input_placeholder: 'Standaard land',
        label: 'Zoek land',
      },
      title: 'Zoek land van uitgave',
    },
    cross_device_checklist: {
      button_primary: 'Verzend verificatie',
      info: 'Tips',
      list_item_doc_multiple: 'Documenten geüpload',
      list_item_doc_one: 'Document geüpload',
      list_item_selfie: 'Foto van uzelf geüpload',
      list_item_video: 'Video geüpload',
      subtitle: 'Wij zijn nu gereed om uw identiteit te verifiëren',
      title: 'Bedankt, dat is alles wat we nodig hebben',
    },
    cross_device_error_desktop: {
      subtitle: 'Deze linkt werkt alleen op een mobiele telefoon',
      title: 'Er ging iets verkeerd',
    },
    cross_device_error_restart: {
      subtitle: 'U zult de verificatie moeten herstarten op uw computer',
      title: 'Er ging iets verkeerd',
    },
    cross_device_intro: {
      button_primary: 'Ontvang de beveiligde link',
      list_accessibility: 'Vereiste stappen om door te gaan met de verificatie op uw mobiele telefoon',
      list_item_finish: 'Kom hier terug om uw aanvraag af te ronden',
      list_item_open_link: 'Open de link en voltooi de openstaande taken',
      list_item_send_phone: 'Stuur een beveiligde link naar uw mobiele telefoon',
      subtitle: 'Zo werkt het:',
      title: 'Ga verder op uw telefoon',
    },
    cross_device_return: {
      body: 'Uw computer heeft wellicht enkele seconden nodig om te updaten',
      subtitle: 'U kunt nu verder gaan op uw computer',
      title: 'Upload geslaagd',
    },
    doc_confirmation: {
      alert: {
        blur_detail: 'Zorg ervoor dat u een heldere foto kunt maken',
        blur_title: 'Onscherpe foto gedetecteerd',
        crop_detail: 'Zorg ervoor dat het gehele document goed zichtbaar is',
        crop_title: 'Afgesneden beelden gedetecteerd',
        glare_detail: 'Stap weg van direct licht',
        glare_title: 'Schitteringen gedetecteerd',
        no_doc_detail: 'Zorg ervoor dat het gehele document goed zichtbaar is in de foto',
        no_doc_title: 'Geen document gevonden',
      },
      body_bank_statement: 'Zorg ervoor dat details goed leesbaar zijn, scherp zijn en zonder schittering',
      body_benefits_letter: 'Zorg ervoor dat details goed leesbaar zijn, scherp zijn en zonder schittering',
      body_bill: 'Zorg ervoor dat details goed leesbaar zijn, scherp zijn en zonder schittering',
      body_id: 'Zijn de details op uw kaart goed leesbaar? Is de foto scherp en zonder schitteringen?',
      body_image_medium: 'De verificatie kan langer duren indien het document niet goed leesbaar is',
      body_image_poor: 'Om u goed te kunnen verifiëren hebben wij een betere foto nodig',
      body_license: 'Is uw rijbewijs goed leesbaar? Is de foto scherp en zonder schitteringen?',
      body_passport: 'Is uw paspoort goed leesbaar? Is de foto scherp en zonder schitteringen?',
      body_permit: 'Is uw verblijfsvergunning goed leesbaar? Is de foto scherp en zonder schitteringen?',
      body_tax_letter: 'Zijn alle details goed leesbaar? Is de foto scherp en zonder schitteringen?',
      button_close: 'Sluiten',
      button_primary_redo: 'Opnieuw',
      button_primary_upload: 'Bevestig',
      button_primary_upload_anyway: 'Toch uploaden',
      button_secondary_redo: 'Opnieuw',
      button_zoom: 'Vergroot afbeelding',
      image_accessibility: 'Foto van uw document',
      title: 'Controleer uw foto',
    },
    doc_select: {
      button_bank_statement: 'een recent bankafschrift;',
      button_benefits_letter: 'Opgave van uw uitkeringsinstantie',
      button_benefits_letter_detail: 'Opgave van uw uitkeringsinstantie zoals bijvoorbeeld WW uitkering of Pensioen uitkering.',
      button_bill: 'een document vanuit een overheidsinstantie',
      button_bill_detail: 'Gas, water, licht, telefoon, internet',
      button_government_letter: 'een document vanuit een overheidsinstantie',
      button_government_letter_detail: 'Een document vanuit een overheidsinstantie zoals WW opgave of belastingaanslag',
      button_id: 'Identiteitskaart',
      button_id_detail: 'Voor en achterzijde',
      button_license: 'Rijbewijs',
      button_license_detail: 'Voor en achterzijde',
      button_passport: 'Paspoort',
      button_passport_detail: 'Pagina met uw pasfoto',
      button_permit: 'Verblijfsvergunning',
      button_permit_detail: 'Voor en achterzijde',
      button_tax_letter: 'Gemeentebelastingen document',
      extra_estatements_ok: 'digitale afschriften worden geaccepteerd',
      extra_no_mobile: 'Excuus, geen mobiele telefoonrekeningen ',
      list_accessibility: 'Documenten die u kunt gebruiken om uw identiteit te verifieren',
      subtitle: 'Dit dient een officieel identiteitsbewijs met foto te zijn',
      subtitle_poa: 'De volgende documenten bevatten hoogst waarschijnlijk uw huidige woonadres',
      title: 'Kies uw document',
      title_poa: 'Selecteer een %{country} document',
    },
    doc_submit: {
      button_link_upload: 'of upload foto - geen scans of fotokopieën toegestaan',
      button_primary: 'Ga verder op telefoon',
      subtitle: 'Neem een foto met uw telefoon',
      title_bank_statement: 'Verstuur bankafschrift',
      title_benefits_letter: 'Verstuur brief ',
      title_bill: 'Verstuur rekening',
      title_government_letter: 'Overheidsdocument',
      title_id_back: 'Neem een foto van de achterzijde van uw identiteitskaart',
      title_id_front: 'Neem een foto van de voorzijde van uw identiteitskaart',
      title_license_back: 'Verstuur rijbewijs toe (achterzijde)',
      title_license_front: 'Verstuur rijbewijs toe (voorzijde)',
      title_passport: 'Neem een foto van de fotopagina van uw paspoort',
      title_permit_back: 'Neem een foto van de achterzijde van uw verblijfsvergunning',
      title_permit_front: 'Neem een foto van de voorzijde van uw verblijfsvergunning',
      title_tax_letter: 'Verstuur belastingaangifte',
    },
    error_unsupported_browser: {
      subtitle_android: 'Herstart het proces met de laatste versie van Google Chrome',
      subtitle_ios: 'Herstart het proces met de laatste versie van Safari',
      title_android: 'Niet ondersteunde browser',
      title_ios: 'Niet ondersteunde browser',
    },
    generic: {
      accessibility: {
        close_sdk_screen: 'Sluit het verificatie scherm',
        dismiss_alert: 'Dismiss alert',
      },
      back: 'terug',
      close: 'Sluit',
      errors: {
        interrupted_flow_error: {
          instruction: 'Probeer het proces opnieuw te starten op een ander apparaat',
          message: 'Camera niet gedetecteerd',
        },
        invalid_size: {
          instruction: 'Maximaal 10MB',
          message: 'Maximum grootte overschreden',
        },
        invalid_type: {
          instruction: 'Probeer een andere type file',
          message: 'Bestand niet geupload',
        },
        lazy_loading: {
          message: 'Er is een fout opgetreden tijdens het laden',
        },
        multiple_faces: {
          instruction: 'Alleen uw gezicht mag in de foto van uzelf',
          message: 'Meerdere gezichten gedetecteerd',
        },
        no_face: {
          instruction: 'Uw gezicht is vereist in de foto van uzelf',
          message: 'Geen gezicht gedetecteerd',
        },
        request_error: {
          instruction: 'Probeer het a.u.b. opnieuw',
          message: 'Connectie verloren',
        },
        sms_failed: {
          instruction: 'Kopier de link naar uw telefoon',
          message: 'Er ging iets mis',
        },
        sms_overuse: {
          instruction: 'Kopier de link naar uw telefoon',
          message: 'Teveel verkeerde pogingen',
        },
        unsupported_file: {
          instruction: 'Probeer een JPG of een PNG bestand',
          message: 'Document wordt niet ondersteund',
        },
      },
      lazy_load_placeholder: 'Laden...',
      loading: 'Laden...',
    },
    get_link: {
      alert_wrong_number: 'Controleer of uw nummer correct is',
      button_copied: 'Gekopieerd',
      button_copy: 'Kopieer',
      button_submit: 'Verstuur link',
      info_qr_how: 'Hoe scant u de QR code?',
      info_qr_how_list_item_camera: 'Richt de camera van uw telefoon op de QR code',
      info_qr_how_list_item_download: 'Indien het niet werkt download dan een QR code scanner van Google Play of vanuit de App Store',
      link_divider: 'of',
      link_qr: 'Scan QR code',
      link_sms: 'Ontvang link via SMS',
      link_url: 'Kopier link',
      loader_sending: 'Versturen',
      number_field_input_placeholder: 'Voer uw mobiele nummer in',
      number_field_label: 'Voer mobiele nummer in',
      subtitle_qr: 'Scan de QR code met uw mobiel',
      subtitle_sms: 'Stuur deze eenmalige link naar uw telefoon',
      subtitle_url: 'Open de link op uw mobiele telefoon',
      title: 'Ontvang de beveiligde link',
      url_field_label: 'Kopieer de link in uw mobiele browser',
    },
    linked_computer: {
      button_primary: 'Doorgaan',
      info: 'Zorg ervoor',
      list_item_desktop_open: 'Uw desktop tabblad blijft open',
      list_item_sent_by_you: 'De link is door u verstuurd',
      subtitle: 'Ga door met de verificatie',
      title: 'Verbonden met uw computer',
    },
    mobilePhrases: {
      photo_upload: {
        body_id_back: 'Neem een foto van de achterzijde van uw kaart',
        body_id_front: 'Neem een foto van de voorzijde van uw kaart',
        body_license_back: 'Neem een foto van de achterzijde van uw rijbewijs',
        body_license_front: 'Neem een foto van de voorzijde van uw rijbewijs',
        body_passport: 'Neem een foto van de paspoort fotopagina',
        body_selfie: 'Neem een foto van uw gezicht',
      },
      selfie_capture: {
        alert: {
          camera_inactive: {
            detail: 'Neem een foto met de </fallback> standaard camera modus </fallback>',
          },
          camera_not_working: {
            detail: 'Neem een foto met de </fallback> standaard camera modus </fallback>',
          },
        },
      },
      upload_guide: {
        button_primary: 'Neem een foto',
        title: 'Paspoort fotopagina',
      },
    },
    outro: {
      body: 'Dank u wel',
      title: 'Verificatie compleet',
    },
    permission_recovery: {
      button_primary: 'Vernieuw',
      info: 'Herstel',
      list_header_cam: 'Volg deze stappen om uw camera opnieuw toegang te geven',
      list_item_action_cam: 'Ververs deze pagina om het identificatie verificatie proces te herstarten',
      list_item_how_to_cam: 'Geef toegang aan uw camera vanuit uw browser instellingen',
      subtitle_cam: 'Geef camera opnieuw toegang om gezichtverficatie te continueren',
      title_cam: 'Camera toegang is geweigerd',
    },
    permission: {
      body_cam: 'Wij kunnen u niet verifieren zonder het gebruik van uw camera',
      button_primary_cam: 'Inschakelen camera',
      subtitle_cam: 'Wanneer gevraagd dient u eerst toegang te geven tot uw camera',
      title_cam: 'Geef toegang tot uw camera',
    },
    photo_upload: {
      body_bank_statement: 'Geef het hele document weer voor het beste resultaat',
      body_benefits_letter: 'Geef het hele document weer voor het beste resultaat',
      body_bill: 'Geef het hele document weer voor het beste resultaat',
      body_government_letter: 'Geef het hele document weer voor het beste resultaat',
      body_id_back: ' ',
      body_id_front: ' ',
      body_license_back: ' ',
      body_license_front: ' ',
      body_passport: ' ',
      body_selfie: ' ',
      body_tax_letter: 'Geef de hele pagina weer voor het beste resultaat',
      button_take_photo: 'Neem een foto',
      button_upload: 'Upload',
      title_selfie: 'Foto van uzelf',
    },
    poa_guidance: {
      button_primary: 'Doorgaan',
      instructions: {
        address: 'Huidig adres',
        full_name: 'Volledige naam',
        issue_date: 'Datum van uitgifte',
        label: 'Zorg ervoor dat alles goed duidelijk is',
        logo: 'Logo',
      },
      subtitle_bank_statement: 'Moet zijn uitgegeven in de <strong>laatste 3 maanden</strong>',
      subtitle_benefits_letter: 'Moet zijn uitgegeven in de <strong>laatste 12 maanden</strong>',
      subtitle_bill: 'Moet zijn uitgegeven in de <strong>laatste 12 maanden</strong>',
      subtitle_government_letter: 'Moet zijn uitgegeven in de <strong>laatste 12 maanden</strong>',
      subtitle_tax_letter: 'Moet zijn uitgegeven in de <strong>laatste 12 maanden</strong>',
    },
    poa_intro: {
      button_primary: 'Start verificatie',
      list_matches_signup: '<strong>Komt overeen met</strong> het adres dat u gebruikt heeft met uw aanvraag',
      list_most_recent: 'Is uw meest <strong>recente</strong> document',
      list_shows_address: 'Toont uw <strong>huidige</strong> adres',
      subtitle: 'U heeft een document nodig dat:',
      title: 'Laten we uw %{country} adres',
    },
    selfie_capture: {
      alert: {
        camera_inactive: {
          detail:
            'Controleer of uw verbonden bent en alles goed werkt. U kunt ook <fallback>verdergaan met de verificatie via uw telefoon</fallback>',
          detail_no_fallback: 'Zorg ervoor dat uw apparaat een werkende camera heeft',
          title: 'Werkt uw camera niet?',
        },
        camera_not_working: {
          detail: 'De verbinding lijkt verbroken. <fallback>Probeer het via anders via uw telefoon</fallback>',
          detail_no_fallback: 'Zorg ervoor dat de camera van uw apparaat werkt',
          title: 'Camera werkt niet',
        },
        timeout: {
          detail: 'Druk op stop wanneer u klaar bent. <fallback>Opnieuw</fallback>',
          title: 'Het duurde te lang',
        },
      },
      button_accessibility: 'Neem een foto',
      frame_accessibility: 'Zo ziet het eruit',
      title: 'Neem een foto van uzelf ',
    },
    selfie_confirmation: {
      image_accessibility: 'Foto van uw gezicht',
      subtitle: 'Zorg ervoor dat uw gezicht duidelijk zichtbaar is op de foto',
      title: 'Bekijk uw eigen foto',
    },
    selfie_intro: {
      button_primary: 'Doorgaan',
      list_accessibility: 'Tips om een goede foto van uzelf te maken',
      list_item_face_forward: 'Gezicht naar voren en zorg ervoor dat uw ogen goed zichtbaar zijn',
      list_item_no_glasses: 'Verwijder uw bril indien nodig',
      subtitle: 'We vergelijken het met uw document',
      title: 'Neem een foto van uzelf',
    },
    sms_sent: {
      info: 'Tips',
      info_link_expire: 'Uw link is 1 uur geldig',
      info_link_window: 'Houd dit tabblad open wanneer u uw telefoon gebruikt',
      link: 'Link nogmaals versturen',
      subtitle: 'We hebben een beveiligde link gestuurd naar %{number}',
      subtitle_minutes: 'Het kan enkele minuten duren voordat u het ontvangt',
      title: 'Controleer uw mobiel',
    },
    switch_phone: {
      info: 'Tips',
      info_link_expire: 'De link die u heeft ontvangen op uw mobiele telefoon is 1 uur geldig',
      info_link_refresh: 'Ververs deze pagina niet',
      info_link_window: 'Houd dit tabblad open wanneer u uw telefoon gebruikt',
      link: 'Cancel',
      subtitle: 'Wanneer u gereed bent gaan we door naar de volgende stap',
      title: 'Verbonden met uw mobiele telefoon',
    },
    upload_guide: {
      button_primary: 'Neem een foto',
      image_detail_blur_alt: 'Voorbeeld van een onscherp document',
      image_detail_blur_label: 'Alle details moeten goed zichtbaar zijn - niet vaag',
      image_detail_cutoff_alt: 'Voorbeeld van een afgesneden document',
      image_detail_cutoff_label: 'Laat alle details zien - inclusief de onderste 2 regels',
      image_detail_glare_alt: 'Voorbeeld van een document met schitteringen',
      image_detail_glare_label: 'Stap uit direct licht - geen schittering',
      image_detail_good_alt: 'Voorbeeld document',
      image_detail_good_label: 'Op de foto dient uw document goed zichtbaar te zijn',
      subtitle: 'Scans en fotokopieën zijn niet toegestaan',
      title: 'Neem een foto van de fotopagina van uw paspoort',
    },
    video_capture: {
      body: 'Plaats uw gezicht in het ovaal',
      body_next: 'Wanneer u gereed bent, klik op volgende',
      body_record: 'Druk op opnemen en volg de instructies',
      body_stop: 'Wanneer u gereed bent, klik op stop',
      button_primary_next: 'Volgende',
      button_record_accessibility: 'Start opnemen',
      button_stop_accessibility: 'Stop opnemen',
      frame_accessibility: 'Camera standpunt',
      header: {
        challenge_digit_instructions: 'Zeg elk cijfer hardop',
        challenge_turn_left: 'Linker',
        challenge_turn_right: 'Rechter',
        challenge_turn_template: 'Kijk over uw %{side} schouder',
      },
      status: 'Opnemen',
    },
    video_confirmation: {
      title: 'Bekijk uw de video van uzelf',
      video_accessibility: 'Speel uw opgenomen video nogmaals af',
    },
    video_intro: {
      button_primary: 'Doorgaan',
      list_accessibility: 'Stappen om een video van uzelf te maken',
      list_item_actions: 'Wij vragen u uzelf op te nemen terwijl u <strong>2 simpele handelingen uitvoerd</strong>',
      list_item_speak: 'Een daarvan vereist dat u <strong>hardop spreekt</strong>',
      title: 'Laten we zorgen dat niemand u imiteert',
    },
    welcome: {
      description_p_1: 'Om een bankrekening te openen zullen wij uw gegevens moeten verifieren ',
      description_p_2: 'Het duurt maar enkele minuten',
      next_button: 'Veifieer identiteit',
      title: 'Open uw nieuwe bankrekening',
    },
  },
};
