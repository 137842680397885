export interface ErrorInterface {
  heading: string;
  message: string;
  type: string;
}

export const errors = {
  errorDefault: {
    heading: 'Error',
    message:
      'U ontvangt binnen ongeveer 20 minuten een e-mail met een link zodat u op ieder gewenst moment de identificatie kan afronden. Uit veiligheidsoverwegingen verloopt deze link na 48 uur.',
    type: 'default',
  },
  errorExpired: {
    heading: 'Deze link is verlopen',
    message: 'Indien u de identificatie wilt voltooien, neem dan contact met ons op via 020- 504 8000.\n\nU kunt dit scherm sluiten.',
    type: 'error-expired',
  },
  errorExpiredRem: {
    heading: 'Deze link is verlopen',
    message: `Wij verzoeken u vriendelijk het opnieuw te proberen door de link opnieuw te openen. Klik <a href="https://www.americanexpress.com/nl-nl/service/identiteit/">hier</a> om het online identificatieproces opnieuw te starten.`,
    type: 'error-expired',
  },
  errorMissingCookie: {
    heading: 'Heeft u iDIN gestart vanuit een andere browser?',
    message: `We hebben niet terug kunnen vinden dat u iDIN gestart bent in deze browser, daarom kunnen we u niet inloggen. <br/><br/>Wat kunt u proberen?<br/><br/>Binnen 20 minuten ontvangt u van ons een link per email waarmee u het proces opnieuw kunt starten.<ul><li>Klik op de link op een desktopcomputer of;</li><li>Als u het op uw mobiele telefoon opent, zorg er dan voor dat u uw standaardbrowser gebruikt (anders werkt het niet).</li></ul>`,
    type: 'error-cookie',
  },
  errorMissingCookieRem: {
    heading: 'Heeft u iDIN gestart vanuit een andere browser?',
    message: `We hebben niet terug kunnen vinden dat u iDIN gestart bent in deze browser, daarom kunnen we u niet inloggen. </br></br>Wat kunt u proberen?<ul><li>Probeer de identiteitsverificatie uit te voeren op een desktopcomputer of;</li><li>Als u het op uw mobiele telefoon opent, zorg er dan voor dat u uw standaardbrowser gebruikt (anders werkt het niet).</li></ul>`,
    type: 'error-cookie',
  },
  errorOnfidoTokenRem: {
    heading: 'Error',
    message:
      'In verband met een technische storing kunnen wij op dit moment het identificatieproces niet voltooien. Wij vragen u vriendelijk het op een later moment nog eens te proberen.',
    type: 'error-token-rem',
  },
  errorOnfidoTokenAcq: {
    heading: 'Error',
    message:
      'U ontvangt binnen ongeveer 20 minuten een e-mail met een link zodat u op een ander moment de identificatie kan afronden. Uit veiligheidsoverwegingen verloopt deze link na 48 uur.',
    type: 'error-token-acq',
  },
};
