import React, { useState, useContext, Fragment } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import { StoreContext } from '../App';
import { Modal } from '../modal/component';
import { default as onfidoLogo } from '../../assets/onfido_logo.svg';
import { default as idinLogo } from '../../assets/idin_logo.svg';

import './styles.scss';
import { flowDefault, flowRemediation } from '../../constants/clientConfigDefaults'
import { StepInterface } from '../../constants/steps';

interface StepComponentInterface {
  content: StepInterface;
  labels: {
    applicationStart: string;
    idin: string;
    onfido: string;
  };
  current: StepInterface;
  learnMore: string;
  stepIndex: number;
  handleLearnMore: Function;
  classNames?: string;
}
const isEdgeChromium =
  !!window['chrome'] && (!!window['chrome'].webstore || !!window['chrome'].runtime) && navigator.userAgent.indexOf('Edg') != -1;

const Step: React.FC<StepComponentInterface> = ({
  content,
  labels,
  current,
  learnMore,
  stepIndex,
  handleLearnMore,
  classNames,
}: StepComponentInterface) => {
  return (
    <div
      className={`step ${content.status} ${content.step === current.step ? 'active' : ''} step-${stepIndex + 1} ${
        classNames ? classNames : ''
      }`}
    >
      <span className={`step__count ${isEdgeChromium ? 'step__auto-height' : ''}`}>
        {content.status === 'done' ? <span className="icon-step-done" /> : content.step}
      </span>
      <span className="step__label">{labels[content.label]}</span>
      {content.link && (
        <a href="#" onClick={(): void => handleLearnMore(content.link)}>
          {learnMore}
        </a>
      )}
    </div>
  );
};

export const Steps: React.FC = observer(() => {
  const [idinModal, setIdinModal] = useState(false);
  const [onfidoModal, setOnfidoModal] = useState(false);
  const store = useContext(StoreContext);

  const { currentStep, steps, showSteps } = store.AppState;
  const { flow } = store.ClientState;
  const { translations } = store.AppConfig;
  const { stepsLabels, learnMore } = translations;

  const handleLearnMore = (modalName?: string): void => {
    if (modalName) {
      if (modalName === 'idin') {
        setIdinModal(true);
      } else if (modalName === 'onfido') {
        setOnfidoModal(true);
      }
    }
  };

  return (
    <Fragment>
      <div className="steps__wrapper">
        <div className={classnames('steps', showSteps ? '' : 'steps--hidden', flow === flowDefault ? 'withApplication' : '')}>
          {flow === flowDefault &&
            steps.map((item, index) => (
              <Step
                key={index + 1}
                content={item}
                labels={stepsLabels}
                current={currentStep}
                learnMore={learnMore}
                stepIndex={index}
                handleLearnMore={handleLearnMore}
              />
            ))}
          {flow === flowRemediation &&
            steps
              .slice(1)
              .map((item, index) => (
                <Step
                  key={index + 1}
                  content={{ ...item, step: item.step - 1 }}
                  labels={stepsLabels}
                  current={{ ...currentStep, step: currentStep.step - 1 }}
                  learnMore={learnMore}
                  stepIndex={index}
                  handleLearnMore={handleLearnMore}
                  classNames="noApplication"
                />
              ))}
        </div>
      </div>
      <Modal modalVisible={idinModal} setModalVisible={setIdinModal}>
        <div className="modal--idin-steps">
          <img src={idinLogo} />
          <p>{translations.idinInfoModal}</p>
        </div>
      </Modal>
      <Modal modalVisible={onfidoModal} setModalVisible={setOnfidoModal}>
        <div className="modal--onfido-steps">
          <img src={onfidoLogo} />
          <p>{translations.onfidoInfoModal}</p>
        </div>
      </Modal>
    </Fragment>
  );
});
