export const attemptLivenessTimeSecondsDefault = 2 * 24 * 60 * 60
export const keepAliveWindowSecondsDefault = 20 * 60
export const terminateProcessDefault = {
  terminateProcessEnabled: true,
  attemptExpirationBufferTimeSeconds: 0,
}
// eslint-disable-next-line @typescript-eslint/naming-convention
export const enableNotMaskedDocumentsInPdfIfBsnMaskingFailedDefault = false
export const flowDefault = 'amex-nl-b2c-acquisition'
export const flowRemediation = 'amex-nl-b2c-remediation'
