import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { default as DrivingLicenceIcon } from '../../assets/documentIcons/driving_licence.svg';
import { default as NationalIdentityCardIcon } from '../../assets/documentIcons/national_identity_card.svg';
import { default as PassportIcon } from '../../assets/documentIcons/passport.svg';
import { default as ResidencePermitIcon } from '../../assets/documentIcons/residence_permit.svg';
import { StoreContext } from '../App';

import './styles.scss';

export interface DocumentSelectorInterface {
  selection: string
  setSelection: (string) => void
  showedDocuments: {
    passport: boolean,
    driving_licence: boolean,
    national_identity_card: boolean,
    residence_permit: boolean
  }
}

export type DocumentTypeInterface = 'passport' | 'driving_licence' | 'national_identity_card' | 'residence_permit'

export const DocumentSelector: React.FC<DocumentSelectorInterface> = observer((
  {
    selection,
    setSelection,
    showedDocuments
  }: DocumentSelectorInterface) => {
  const store = useContext(StoreContext);
  const [listState, setListState] = useState({
    passport: false,
    national_identity_card: false,
    residence_permit: false,
    driving_licence: false,
  })

  const { translations } = store.AppConfig;
  const { selectedCountry } = store.ClientState;

  const {
    selectDocument,
    passport,
    national_identity_card,
    residence_permit,
    driving_licence
  } = translations.pageCountryAndDocumentSelect;

  function getLabel(key: DocumentTypeInterface) {
    switch (key) {
      case 'passport':
        return passport;
      case 'driving_licence':
        return driving_licence;
      case 'national_identity_card':
        return national_identity_card;
      case 'residence_permit':
        return residence_permit;
      default:
        break;
    }
  }

  function getIcon(key: DocumentTypeInterface) {
    switch (key) {
      case 'passport':
        return <img className="document-selector__passport-icon" src={PassportIcon} />;
      case 'driving_licence':
        return <img className="document-selector__driving_licence" src={DrivingLicenceIcon} />;
      case 'national_identity_card':
        return <img className="document-selector__national_identity_card" src={NationalIdentityCardIcon} />;
      case 'residence_permit':
        return <img className="document-selector__residence_permit" src={ResidencePermitIcon} />;
      default:
        break;
    }
  }

  function handleListState(key) {
    const actionState = {
      passport: false,
      national_identity_card: false,
      residence_permit: false,
      driving_licence: false
    }

    Object.keys(listState).map((listKey => {
      if (listKey === key) {
        actionState[listKey] = true
      } else {
        actionState[listKey] = false
      }
    }))

    setListState(actionState)
    setSelection(actionState)
  }

  useEffect(() => {
    console.log(showedDocuments)
  }, [showedDocuments])

  return (
    <div className="document-selector">
      <h2>{selectDocument}</h2>
      <div className="document-selector__list">
        {Object.keys(listState).map((key: DocumentTypeInterface, index) => {
          if (key === 'driving_licence' && selectedCountry === 'NL') {
            return (
              <button
                className={`document-selector__document disabled ${listState[key] ? 'active' : ''}`}
                key={`document--${index}`}
                disabled
              >
                <div className="document-icon">
                  {getIcon(key)}
                </div>
                <div className="document-label">
                  <span>
                    {getLabel(key)}
                  </span>
                </div>
              </button>
            )
          } else {
            if (showedDocuments[key] === true) {
              return (
                <button
                  className={`document-selector__document ${listState[key] ? 'active' : ''}`}
                  key={`document--${index}`}
                  onClick={() => handleListState(key)}
                >
                  <div className="document-icon">
                    {getIcon(key)}
                  </div>
                  <div className="document-label">
                    <span>
                      {getLabel(key)}
                    </span>
                  </div>
                </button>
              )
            } else {
              return <div key={`document--${index}`} />
            }
          }

        })}
      </div>
    </div>
  );
});
