import React from 'react';

import { default as amexLogoFlat } from '../../assets/ae_logo_flat.svg';

import './styles.scss';

export const Topbar: React.FC = () => {
  return (
    <div className="topbar">
      <a href="https://www.americanexpress.com/nl/" target="_blank" rel="noreferrer">
        <img src={amexLogoFlat} />
      </a>
    </div>
  );
};
