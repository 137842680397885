import React, { useState, useEffect, useRef } from 'react';

import './styles.scss';

interface ValueInterface {
  name: string;
  value: string;
}

interface CategoryInterface {
  name: string;
  values: ValueInterface[];
}

export interface SelectInputInterface {
  categories: CategoryInterface[];
  onChange: Function;
  placeholder: string;
}

export const SelectInput: React.FC<SelectInputInterface> = ({ categories, onChange, placeholder = 'Select' }: SelectInputInterface) => {
  const node = useRef<any>(null);
  const [currentValue, setCurrentValue] = useState({
    name: '',
    value: '',
  });
  const [listVisible, setListVisible] = useState(false);

  const handleValue = (value: ValueInterface): void => {
    setCurrentValue(value);
    setListVisible(false);
  };

  const handleOutsideClick = (event: { target: object }): void => {
    if (node.current.contains(event.target)) {
      return;
    }
    setListVisible(false);
  };

  useEffect(() => {
    onChange(currentValue);
  }, [currentValue]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className={`select-input ${listVisible ? 'select-input--focus' : ''}`} ref={node}>
      <div className="select-input__value" onClick={() => setListVisible(!listVisible)}>
        {currentValue.name.length ? currentValue.name : placeholder}
        <span className={`select-input__arrow ${listVisible ? 'select-input__arrow--down' : 'select-input__arrow--up'}`}></span>
      </div>
      {listVisible && (
        <ul className="select-input__list">
          {categories.map((category: CategoryInterface) => (
            <li key={category.name}>
              <span className="select-input__category-name">{category.name}</span>
              <ul>
                {category.values.map((value) => (
                  <li key={value.value} className={value.value} onClick={() => handleValue(value)}>
                    {value.name}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
